import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.section`
  @media (min-width: 1400px) {
    min-height: 515px;
    max-width: 1500px;
    margin: 0 auto;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 1400px) {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 515px;
    width: 727px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    &:first-child {
      font-size: 50px;
      line-height: 40px;
      margin-bottom: 0;
    }
  }
`

const TextImage: React.FC<TextProps> = ({ fields }) => {
  return (
    <Section className="position-relative mb-5 pb-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Image image={fields.image} alt="" />
          </div>
          <div className="col-md-6">
            <div className="px-md-3">
              <Content
                content={fields.description}
                className="p-lg-5 py-5 mt-5 mt-md-0"
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TextImage
